//eslint-disabled
import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import QuizIcon from "@mui/icons-material/Quiz";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CircularProgress, MenuItem } from "@mui/material";
import { BASE_URL } from "../utils/constants";
import axios from "axios";
import { toast } from "react-toastify";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://homeclassgroup.com">
        Homeclass Nigeria
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function RequestForm() {
  const INITIAL_STATE = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    product: "",
  };
  const [value, setValue] = useState(INITIAL_STATE);

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [products, setProducts] = useState([]);

  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  function isEmail(emailAdress) {
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (emailAdress.match(regex)) return true;
    else return false;
  }

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const { data } = await axios.get(BASE_URL + "/products/get-product");
        setProducts(data?.product);
        console.log(data);

        if (data?.product?.length === 0) {
          toast.info("No Product available");
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        if (error?.response?.status && error?.response?.data) {
          toast.error(error?.response?.data);
        } else {
          toast.error("An error occurred while fetching available products");
        }

        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleSubmit = async (e) => {
    if (
      value?.firstName === "" ||
      value?.lastName === "" ||
      value?.email === "" ||
      value?.phone === "" ||
      value?.product === ""
    ) {
      toast.error("All fields are required");
    } else if (!isEmail(value?.email)) {
      toast.error("Please enter a valid email");
    } else {
      setSubmitting(true);
      axios
        .post(BASE_URL + "/contact/create", {
          firstName: value.firstName,
          lastName: value.lastName,
          email: value.email,
          phoneNumber: value.phone,
          interestedProduct: value.product,
        })
        .then((res) => {
          if (res?.data?.isCreated || res?.data?.isUpdated) {
            window.alert(res?.data?.message);
            window.location.href = "https://homeclassgroup.com";
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data);
          setSubmitting(false);
        });
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <QuizIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Request Call from Our Agent
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      value={value.firstName}
                      onChange={(e) => handleChange(e)}
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      value={value.lastName}
                      onChange={(e) => handleChange(e)}
                      name="lastName"
                      autoComplete="family-name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      value={value.email}
                      onChange={(e) => handleChange(e)}
                      name="email"
                      autoComplete="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="phone"
                      label="Phone Number"
                      value={value.phone}
                      onChange={(e) => handleChange(e)}
                      type="number"
                      autoComplete="phone-number"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="product"
                      label="Select Product/Services"
                      value={value.product}
                      onChange={(e) => handleChange(e)}
                      select
                    >
                      <MenuItem value="">
                        ...Select product/services to enquire...
                      </MenuItem>
                      {products
                        .sort(function (a, b) {
                          if (a.productTitle < b.productTitle) {
                            return -1;
                          }
                          if (a.productTitle > b.productTitle) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((item) => {
                          return (
                            <MenuItem
                              value={item?.productTitle}
                              key={item?.productTitle}
                            >
                              {item?.productTitle}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </Grid>
                </Grid>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={submitting}
                  onClick={handleSubmit}
                >
                  {submitting ? (
                    <CircularProgress
                      size={25}
                      style={{ margin: "0px auto" }}
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </>
            )}
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
