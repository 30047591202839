import RequestForm from "./component/RequestForm";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from "react";

function App() {
  return <React.Fragment>
    <RequestForm />
    <ToastContainer position="top-center" />
  </React.Fragment>;
}

export default App;
